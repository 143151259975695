import React from "react";
import * as styles from "../styles/hamburger.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { subscribeForm } from "./helpers";
import { Link } from "gatsby";
import { useCookies } from "react-cookie";

const Subscribe = ({ showModal, open, handleClick, setModal, language, messages }) => {
  const [cookies, setCookie] = useCookies([]);
  const updateCookie = (lang) => {
    setCookie("language", lang, { path: "/" });
  };
  return (
    <section className={styles.subscribeWrapper}>
      <div className={`${styles.profileCard} ${styles.flex}`}>
        <span className={`${styles.closeIcon} ${styles.socialStyle}`}>
          <FontAwesomeIcon
            icon={faTimes}
            size={"2x"}
            onClick={() => {
              setModal(!showModal);
              handleClick(!open);
            }}
          />
        </span>
        <div className={styles.form}>
          <span className={`${styles.iconClass} ${styles.socialStyle}`}>
            <a
              title="Share on Instagram"
              href="https://www.instagram.com/everyoneisgoodatsomething_/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} size={"2x"} />
            </a>
            <a
              title="Share on Facebook"
              href="https://www.facebook.com/IndiaInclusionSummit"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} size={"2x"} />
            </a>
            <a
              title="Tweet about this on Twitter"
              href="https://twitter.com/IndiaInclusion"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} size={"2x"} />
            </a>
          </span>

          <div className={styles.notifyWrapperText}>
            <span>{messages.notifyOnNewStory}</span>
          </div>

          <div dangerouslySetInnerHTML={subscribeForm(false, language, messages)} />
          <div className={styles.contactStoryWrapper}>
            <span className={styles.contactStorySpan}>
              {messages.shareAStory}
              <br />
              <span className={styles.contactMailWrapper}>
                <a href="mailto:stories.egs@gmail.com">stories.egs@gmail.com</a>
              </span>
            </span>
          </div>

          <div className={styles.notifyWrapperText} id="testingId">
            <Link onClick={() => updateCookie("en")} to="/">
              {messages.english}
            </Link> 
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link onClick={() => updateCookie("hi")} to="/hi">
              {messages.hindi}
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link onClick={() => updateCookie("bn")} to="/bn">
              {messages.bengali}
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link onClick={() => updateCookie("ta")} to="/ta">
              {messages.tamil}
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link onClick={() => updateCookie("ml")} to="/ml">
              {messages.malayalam}
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link onClick={() => updateCookie("kn")} to="/kn">
              {messages.kannada}
            </Link>
          </div>
          <br/>
          <br/>

        </div>
      </div>
    </section>
  );
};

export default Subscribe;
